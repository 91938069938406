:root{
    --app-color:#6c5fc7;
    --primary-color:#6c5fc7;
    --secondary-color:#C52E59;
    --tertiary-color: #000;
    --quaternary-color: #fff;
    --section-title-color:#333333;
    --divider-color:#cccccc;
    --inactive-plan-color:#efefef;
    --active-plan-color:#d3d3d3;
    --documents-color:#7A7A7A;
    --required-color:#ff4149;
    --background-color:#f2f2f2;
  //background: #6c5fc7;
  //border-color: #413496;
    --primary-button-color:var(--app-color);
  --pricing-card-box-shadow: 0px 4px 15px 0px rgb(147 36 210 / 60%);
  --pricing-card-active-plan-bg-image: var(--primary-color);
  --pricing-card-active-plan-text-color: var(--quaternary-color);
  --pricing-card-active-plan-bg-color: #d3d3d3;
  --pricing-card-inactive-plan-bg-color: #f3f3f3;
  --pricing-card-inactive-plan-text: #2ac650;
  --pricing-card-active-plan-text: #2ac650;
  --pricing-card-number-of-likes-color: var(--tertiary-color);
  --pricing-card-number-of-likes-fs-mw1000: 46px;
  --pricing-card-number-of-likes-fs-mw480: 37px;
  --pricing-card-number-of-likes-per-post-color: var(--tertiary-color);
  --pricing-card-price-color: var(--tertiary-color);
  --pricing-card-divider-style: dashed;
  --pricing-card-divider-color: #d8d8d8;
  --pricing-card-divider-width: 2px;
  --pricing-card-feature-color: var(--secondary-color);
  --pricing-card-feature-font-weight: 300;
  --pricing-card-subscribe-button-bg-image: var(--primary-color);
  --pricing-card-subscribe-button-bg-image-hover: linear-gradient(66deg, #04a3e9 -17%, #44bf51 64%);
  --pricing-card-subscribe-button-text-color: var(--quaternary-color);
  --pricing-card-cancel-color: #7a7a7a;
}
