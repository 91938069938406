.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.f-w {
  width: 100%;
}

.f-h {
  height: 100%;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  display: flex;
  width: 100%;
}


.column-even-space {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.row-even-space {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.row-between-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.centered-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.w-50 {
  width: 50%;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.ta-center {
  text-align: center;
}

.underline {
  padding-top: 30px;
  margin-bottom: 50px;
  width: 325px;
  border-bottom: 4px var(--secondary-color) solid;
}

.text-underline {
  text-decoration: underline;
}

.show-class {
  visibility: visible;
}

.section-padding {
  box-sizing: border-box;
  padding: 50px 20px;
  max-width: 1520px;
  width: 100%;
  height: 100%;
}

.section-title {
  color: var(--section-title-color);
  font-weight: 700;
  font-size: 42px;
}

.buy-likes-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 390px;

  @media screen and(max-width: 450px) {
    width: 300px;
  }

  .heart {
    padding: 20px 0 10px 0;
  }

  .widget-title {
    padding-bottom: 55px;
  }

  .widget-instruction {
    position: relative;
    top: 19px;
  }

  .sale-container {
    min-height: 75px;
    padding-top: 20px;
    margin: 10px 0;

    .sale-outer {
      width: 65px;
      height: 65px;
      padding: 3px 0 0 3px;

      .sale-inner {
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disabled {
  background-color: grey;
}

.required {
  color: var(--required-color);
}
.bg-white{
  background-color: var(--white-color);
}
