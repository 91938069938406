/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
$my-custom-level: mat.define-typography-level(
  $font-family: 'Assistant',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);
@import "./assets/styles/colors";
@import "./assets/styles/common";
@import "./assets/styles/overrides";

html, body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f1f1f1;
}


.relative {
  position: relative !important;
}

.height-full {
  height: 100% !important;
}

.link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase !important;
}
.loading {
  position: absolute;
  //top: 48px;
  //bottom: 80px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-red {
  color: var(--required-color) !important;
}
.app-tooltip {
  //background: rgba(80, 43, 118, 0.4);
}
.message-title {
  text-align: left;
  display: block;
  color: var(--primary-color);
  margin-bottom: 10px;
  font-size: 20px;
  // font-weight: 600;
}
.message-box {
  margin-bottom: 10px;
  border: 1px solid rgba(80, 43, 118, 0.4);
  padding: 10px;
}
.mat-primary {
  color: var(--primary-color);
}

.text-capital {
  text-transform: capitalize !important;
}

.btn-danger {
  background-color: var(--required-color);
  color: #ffffff !important;
}

mat-sidenav-content {
  background-color: #f1f1f1;
}


