.mat-card {
  font-family: 'Assistant', sans-serif;
}

.mat-button {
  font-family: 'Assistant', sans-serif;
}

.primary-button.mat-button.mat-button-base {
  background-color: var(--primary-button-color);
  color: white;
  width: 180px;
  height: 50px;
  font-size: 18px;
  border-radius: var(--primary-button-border-radius);
  font-weight: 400;
}

.whatsapp-button.mat-button.mat-button-base {
  background-color: #14a73f;
  width: 160px;
  height: 50px;
  border-radius: 100px;
  font-weight: 400;

  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 20px;
    gap: 10px;
  }


  @media screen and(max-width: 1200px) {
    width: 175px;
    height: 60px;

    @media screen and(max-width: 550px) {
      width: 160px;
      height: 55px;
    }
  }
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
